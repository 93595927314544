import BackgroundFetch from 'cordova-plugin-background-fetch';
import { uploadWearableData, wearableBackgroundSync } from './wearable';
import { store } from '../redux/store';
import { garminBackgroundSyncTimeout } from '../redux/actions/garmin';
import * as Sentry from '@sentry/react';

declare const window: Window;

interface Window {
  BackgroundFetch: typeof BackgroundFetch;
}

export const initializeBackgroundFetch = async (): Promise<void> => {
  console.log('Installing background fetch handler');
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const status = await window.BackgroundFetch.configure(
    { minimumFetchInterval: 2, requiredNetworkType: 1 },
    onBackgroundFetch,
    onBackgroundFetchTimeout,
  );
  console.log('configure status: %d', status);
};

const onBackgroundFetch = async (taskId: string) => {
  Sentry.captureMessage(`onBackgroundFetch attempt`);
  console.log('Starting task %s', taskId);

  console.log('syncing wearable data...');
  try {
    await wearableBackgroundSync();

    // Wait until lastSyncCompleted has been updated so we've got the right time frame
    await waitForGarminSyncSuccess();

    console.log('uploading wearable data...');
    await uploadWearableData(true, true);
    console.log('uploading wearable data done.');
  } catch (e) {
    console.error('Background fetch error', e);
    Sentry.captureException(`Background fetch error: ${e}`);
  } finally {
    console.log('BackgroundFetch completed task: ', taskId);
    Sentry.captureMessage('Background fetch complete');
    // Required: Signal completion of your task to native code
    // If you fail to do this, the OS can terminate your app
    // or assign battery-blame for consuming too much background-time
    try {
      window.BackgroundFetch.finish(taskId);
    } catch (error) {
      console.error('Error finishing background fetch task', error);
      Sentry.captureException(`Error finishing background fetch task: ${error}`);
    }
  }
};

const onBackgroundFetchTimeout = (taskId: string) => {
  console.warn('TIMEOUT Task: ', taskId);
  Sentry.captureException(new Error(`onBackgroundFetchTimeout`));
  store.dispatch(garminBackgroundSyncTimeout());
};

const waitForGarminSyncSuccess = () => {
  return new Promise<void>((resolve) => {
    const currentLastSyncState = store.getState().garmin.lastSyncCompleted;
    const unsubscribe = store.subscribe(() => {
      const newLastSyncState = store.getState().garmin.lastSyncCompleted;

      if (newLastSyncState && currentLastSyncState && newLastSyncState > currentLastSyncState) {
        console.log('Sync complete, about to upload data');
        Sentry.captureMessage('Background sync complete, about to upload data');
        unsubscribe();
        resolve();
      }
    });
  });
};
