import firebase from 'firebase/compat/app';

export default interface IGarminSdkStatus {
  id: string;
  deviceId?: string;
  pir: firebase.firestore.DocumentReference;
  endTime: Date;
  syncErrors: number;
}

export const formatSdkStatusFromFirestore = (
  snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
): IGarminSdkStatus => {
  if (!snapshot.exists) {
    throw new Error('Garmin SDK Status document does not exist in Firestore!');
  }

  let endTime = snapshot.get('endTime');

  if (endTime) {
    endTime = endTime.toDate();
  }

  return {
    id: snapshot.id,
    endTime,
    deviceId: snapshot.get('deviceId'),
    syncErrors: snapshot.get('syncErrors'),
    pir: snapshot.get('pir'),
  };
};
