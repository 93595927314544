import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import Onboarding from '../onboarding/Index';
import Home from '../home/Index';
import Daily from '../daily/Index';
import Messages from '../messages/Index';
import Physiology from '../physiology/Index';
import Places from '../places/Index';
import Photos from '../photos/Index';
import LogOut from '../auth/LogOut';
import GetFcmToken from '../user/GetFcmToken';
import Header from './Header';
import Menu from './Menu';
import FooterNav from './FooterNav';
import Alerts from '../alerts/Index';
import Dashboard from '../dashboardMonitor/Index';
import ProviderDashboard from '../home/ProviderDashboard';
import Vital from '../vitals/Index';
import SurveyModal from '../../components/TakeSurveyModal/SurveyModal';
import MedicationModal from '../../components/ManageMedicationModal/MedicationModal';
import GetStarted from '../onboarding/GetStarted';
import ManageContactModal from '../../components/ManageContactModal/ManageContactModal';
import { onboarded } from '../onboarding/actions';
import DevicePage from '../device/DevicePage';
import { isLinkedUser, isPirLinkedUser } from '../linked-users/utils';
// import { startGeolocationListener, stopGeolocationListener } from '../../lib/geolocation';
import IUser, { DevicePairedStatus, IContextUser, UserRole } from '../user/interfaces/IUser';
import LeftNav from './LeftNav';
import DeviceOnboarding from '../device/DeviceOnboarding';
import EnterName from '../onboarding/EnterName';
import InformedConsent from '../onboarding/InformedConsent';
import { initFirebaseMessagingDeepLinking } from '../firebase/messaging';
import ModalAlert from './ModalAlert';
import { updatePartialUser, updateUserTimezone } from '../user/actions';
import ManageUserInfoModal from 'src/components/ManageUserInfoModal/ManageUserInfoModal';
import IAppointment from '../appointments/interfaces/IAppointment';
import ILinkedUser, { LinkedUserRole } from '../linked-users/interfaces/ILinkedUser';
import { useQueryAppointments } from '../appointments/queryHooks';
import { useModal } from 'src/ModalContext';
import { useDevice } from 'src/DeviceContext';
import { getToDos } from '../todo/actions';
import { store } from '../../redux/store';
import {
  checkIfUserWasPreviouslyPaired,
  getWearableBatteryStatus,
  getWearableInfo,
  NeedsToPairStatus,
  setupWearableLogging,
  wearablePair,
  wearableSync,
} from 'src/lib/wearable';
import * as Sentry from '@sentry/react';
import { clearGarmin, updateNeedsToPair } from 'src/redux/actions/garmin';

export const UserContext = React.createContext<IContextUser | null>(null);

interface ResumeContextType {
  appResumeCount: number;
}

export interface ISelectedUserContext {
  selectedUser: IUser | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<null | IUser>>;
}

export const SelectedUserContext = React.createContext<ISelectedUserContext>({
  selectedUser: null,
  setSelectedUser: () => {
    // do nothing
  },
});

export interface IAppointmentContext {
  appointments: IAppointment[] | null;
  setAppointments: React.Dispatch<React.SetStateAction<null | IAppointment[]>>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppointmentsContext = React.createContext<IAppointmentContext>({
  appointments: null,
  setAppointments: () => {
    // do nothing;
  },
  refresh: false,
  setRefresh: () => {
    // do nothing;
  },
});

const initialResumeContextValue: ResumeContextType = {
  appResumeCount: 0,
};

export const ResumeContext = React.createContext(initialResumeContextValue);

const Index = (props: PropsFromRedux): JSX.Element => {
  const { isMobileDevice, isMobileDeviceAndCordova, isCordova } = useDevice();
  const menuDisclosure = useDisclosure();
  const modalAlertDisclosure = useDisclosure();
  const history = useHistory();
  const loggingOut = props.loggingOut;
  const uid = (props.user === null ? null : props.user.id) as null | string;
  const uname = props.user && props.user.name !== undefined ? props.user.name : '';
  const uemail = props.user ? props.user.email : '';
  const urole = props.user && props.user.role !== undefined ? props.user.role : null;
  const nusers = props.otherLinkedUsersLength;
  const ushowColor = props.user && props.user.showColor !== undefined ? props.user.showColor : true;
  const ushowVitals = props.user && props.user.showVitals !== undefined ? props.user.showVitals : true;
  const uphone = props.user && props.user.phone !== undefined ? props.user.phone : null;
  const uimage = props.user && props.user.image !== undefined ? props.user.image : null;
  const uwantEmail = props.user && props.user.doesWantEmail !== undefined ? props.user.doesWantEmail : false;
  const uwantSms = props.user && props.user.doesWantSms !== undefined ? props.user.doesWantSms : false;
  const uwantPush = props.user ? props.user.doesWantPush : undefined;
  const ufcm =
    props.user && props.user.fcmTokens !== undefined
      ? props.user.fcmTokens
      : props.user && props.user.fcmToken !== undefined
      ? props.user.fcmToken
      : null;
  const userPreferences = props.user && props.user.preferences ? props.user.preferences : { hiddenGraphs: [] };
  const utimezone = props.user && props.user.timezone !== undefined ? props.user.timezone : null;
  const umanuallySetTimezone =
    props.user && props.user.manuallySetTimezone !== undefined ? props.user.manuallySetTimezone : false;
  const ucpGeneralContactInfo =
    props.user && props.user.cpGeneralContactInfo !== undefined ? props.user.cpGeneralContactInfo : null;
  const uonboarded = true; // NOTE: Fix
  const udevicePairedStatus = props.user?.devicePairedStatus !== undefined ? props.user.devicePairedStatus : undefined;

  const contextUser = useMemo(() => {
    if (uid === null || loggingOut) {
      return null;
    }
    return {
      id: uid,
      name: uname,
      email: uemail,
      role: urole,
      hasAccessTo: nusers === 0 ? [] : ['test'],
      showColor: ushowColor,
      showVitals: ushowVitals,
      phone: uphone,
      image: uimage,
      doesWantEmail: uwantEmail,
      doesWantSms: uwantSms,
      doesWantPush: uwantPush,
      fcmTokens: ufcm,
      onboarded: uonboarded,
      preferences: userPreferences,
      timezone: utimezone,
      manuallySetTimezone: umanuallySetTimezone,
      cpGeneralContactInfo: ucpGeneralContactInfo,
      devicePairedStatus: udevicePairedStatus,
    } as IContextUser;
  }, [
    uid,
    uname,
    uemail,
    urole,
    nusers,
    ushowColor,
    ushowVitals,
    uphone,
    uimage,
    uwantEmail,
    uwantSms,
    uwantPush,
    ufcm,
    uonboarded,
    utimezone,
    umanuallySetTimezone,
    ucpGeneralContactInfo,
    udevicePairedStatus,
  ]);

  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const memoSelectedUser = useMemo(() => {
    return { selectedUser, setSelectedUser };
  }, [selectedUser?.id]);
  const isPir = uid !== null && urole === UserRole.USER;
  const isAdmin = uid !== null && urole === UserRole.ADMIN;
  // const readyForGeo = isPir && props.geolocationEnabled === true;
  const [appResumeCount, setAppResumeCount] = useState(0);
  const [appointments, setAppointments] = useState<IAppointment[] | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const memoAppointments = useMemo(
    () => ({ appointments, setAppointments, setRefresh, refresh }),
    [appointments, refresh],
  );
  const { openModal, notificationType, setNotificationType } = useModal();

  const linkedUsers: ILinkedUser[] = !isPir
    ? props.otherLinkedUsers?.filter((potentialLinkedUser): potentialLinkedUser is ILinkedUser => {
        return isLinkedUser(potentialLinkedUser);
      }) ?? []
    : props.supportNetwork?.filter((potentialLinkedUser): potentialLinkedUser is ILinkedUser => {
        return isLinkedUser(potentialLinkedUser);
      }) ?? [];

  const userRole = props.user?.role === UserRole.USER ? LinkedUserRole.PIR : LinkedUserRole.PROVIDER;

  const { data: fetchedAppointments = [], refetch } = useQueryAppointments(props.user?.id ?? '', userRole, linkedUsers);

  useEffect(() => {
    if (refresh) {
      refetch();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setAppointments((prevAppointments) => {
      const prevAppointmentsString = JSON.stringify(prevAppointments);
      const sortedAppointmentsString = JSON.stringify(fetchedAppointments);

      if (prevAppointmentsString !== sortedAppointmentsString) {
        return fetchedAppointments;
      }
      return prevAppointments;
    });
  }, [fetchedAppointments]);

  // Notes from 8/1/24: We are keeping this code around at Ellie's request because she wants to be able to use them to start from when we're ready to pay for the cordova-background-geolocation-lt plugin, which costs
  // if (readyForGeo) {
  //   if (props.geolocationEnabled) {
  //     startGeolocationListener();
  //   } else {
  //     stopGeolocationListener();
  //   }
  // }

  const updateTodos = async (id: string) => {
    try {
      console.log('updating toDos');
      await props.getToDos(id);
    } catch (err) {
      console.error(`Error updating todos: ${err}`);
    }
  };

  const updateDevicePairedStatus = async (id: string, pairedStatus: DevicePairedStatus) => {
    try {
      await props.updatePartialUser(id, { devicePairedStatus: pairedStatus });
    } catch (err) {
      console.error(`Error updating device paired status: ${err}`);
    }
  };

  const checkIfUserWasPaired = async (userId: string) => {
    try {
      console.log('Checking if user was paired');
      const userWasPreviouslyPaired = await checkIfUserWasPreviouslyPaired(userId);
      if (userWasPreviouslyPaired) {
        console.log('Attempting to re-pair');
        store.dispatch(updateNeedsToPair(NeedsToPairStatus.TRYING_TO_PAIR));
        rePairDevice(userId);
      } else {
        console.log('user was not previously paired');
      }
    } catch (err) {
      console.error(`Error checking if user was paired in index: ${err}`);
    }
  };

  const checkIfUserIsAlreadyPaired = async (): Promise<boolean> => {
    console.log('Checking if user is already paired');
    Sentry.captureMessage('checking if user is already paired');
    let isAlreadyPaired = false;
    try {
      const wearableInfo = await getWearableInfo();
      isAlreadyPaired = !!wearableInfo;
    } catch (err) {
      console.error(`Error checking if user is already paired in index: ${err}`);
      return isAlreadyPaired;
    }
    return isAlreadyPaired;
  };

  const rePairDevice = async (id: string) => {
    console.log('repairdevice');
    Sentry.captureMessage('repair device');
    const userIsPaired = await checkIfUserIsAlreadyPaired();
    if (userIsPaired) {
      store.dispatch(updateNeedsToPair(NeedsToPairStatus.DOES_NOT_NEED_TO_PAIR));
      try {
        await setupWearableLogging();
        await wearableSync();
        updateDevicePairedStatus(id, DevicePairedStatus.PAIRED);
      } catch (err) {
        console.error(`Error setting up wearable logging and syncing: ${err}`);
      }
    } else {
      console.log('device has become unpaired, attempting to re-pair');
      Sentry.captureMessage('device has become unpaired, attempting to re-pair');
      try {
        await wearablePair(id, props.updatePartialUser);
        try {
          await getWearableInfo();
          await getWearableBatteryStatus();
          updateDevicePairedStatus(id, DevicePairedStatus.PAIRED);
          store.dispatch(updateNeedsToPair(NeedsToPairStatus.DOES_NOT_NEED_TO_PAIR));
        } catch (err) {
          console.error('Post-pairing error in index:', err);
          updateDevicePairedStatus(id, DevicePairedStatus.BECAME_UNPAIRED);
          store.dispatch(updateNeedsToPair(NeedsToPairStatus.USER_SHOULD_PAIR));
        }
      } catch (err) {
        Sentry.captureException(new Error(`Error pairing Garmin device after it became unpaired: ${err}`));
        const errorText = typeof err === 'string' ? err : JSON.stringify(err);
        if (errorText.toLowerCase() === 'no device found') {
          store.dispatch(clearGarmin());
        }
        store.dispatch(updateNeedsToPair(NeedsToPairStatus.USER_SHOULD_PAIR));
        updateDevicePairedStatus(id, DevicePairedStatus.BECAME_UNPAIRED);
      }
    }
  };

  const syncDeviceOnAppResume = async () => {
    try {
      await wearableSync();
    } catch (err) {
      console.log(`Error syncing after app resume: ${err}`);
      Sentry.captureException(new Error(`sync onAppResume errors: ${err}`));
    }
  };

  const onAppResume = useCallback(() => {
    setAppResumeCount((c) => c + 1);

    const updateTimezone = async () => {
      try {
        await props.updateUserTimezone();
      } catch (err) {
        console.error(`Error updating timezone: ${err}`);
      }
    };

    if (isMobileDeviceAndCordova) {
      const garminState = store.getState().garmin;
      if (!garminState.isSyncing && !garminState.isUploading && !!garminState.name) {
        syncDeviceOnAppResume();
      }
    }

    updateTimezone();
    if (props.user && isPir) {
      updateTodos(props.user.id);
    }
  }, [appResumeCount]);

  initFirebaseMessagingDeepLinking(
    history,
    openModal,
    modalAlertDisclosure,
    isMobileDeviceAndCordova,
    notificationType,
    setNotificationType,
  );

  const hasOnboarded = props.hasOnboarded || (!props.onboarding && (isPir || isAdmin || nusers > 0));

  useEffect(() => {
    if (!props.onboarding && (isPir || nusers > 0)) {
      props.onboarded();
    }
  }, [props]);

  useEffect(() => {
    if (!window.cordova) {
      window.addEventListener('focus', onAppResume);
    }
    return () => {
      window.removeEventListener('focus', onAppResume);
    };
  }, []);

  useEffect(() => {
    if (window.cordova) {
      console.log('i am already in this useEffect in index');
      if (isPir && props.user && store.getState().garmin.needsToPair === NeedsToPairStatus.SHOULD_TRY_TO_PAIR) {
        store.dispatch(updateNeedsToPair(NeedsToPairStatus.TRYING_TO_PAIR));
        rePairDevice(props.user.id);
      }
      // if device became unpaired without the user asking to unpair (for example, on logging out), attempt to re-pair
      else if (isPir && props.user && props.user.devicePairedStatus === DevicePairedStatus.BECAME_UNPAIRED) {
        store.dispatch(updateNeedsToPair(NeedsToPairStatus.TRYING_TO_PAIR));
        rePairDevice(props.user.id);
      } else if (
        // it should be paired but it doesn't have the device info saved
        isPir &&
        props.user &&
        (!store.getState().garmin.name || !store.getState().garmin.id) &&
        props.user.devicePairedStatus === DevicePairedStatus.PAIRED
      ) {
        store.dispatch(updateNeedsToPair(NeedsToPairStatus.TRYING_TO_PAIR));
        rePairDevice(props.user.id);
      } else if (isPir && props.user && !props.user.devicePairedStatus) {
        // this is from an older version of the app, trying to see if they've been paired before.
        checkIfUserWasPaired(props.user.id);
      }
      props.updateUserTimezone();
      if (props.user && isPir) {
        updateTodos(props.user.id);
      }
      // if a pir doesn't have a devicePairedStatus but it looks like they're paired:
      if (isPir && props.user && !props.user.devicePairedStatus && store.getState().garmin.id) {
        updateDevicePairedStatus(props.user.id, DevicePairedStatus.PAIRED);
      }
    }
  }, []);

  const { deviceIsReady } = useDevice();

  useEffect(() => {
    if (deviceIsReady) {
      document.addEventListener('resume', onAppResume, false);

      return () => {
        document.removeEventListener('resume', onAppResume, false);
      };
    }
  }, [deviceIsReady]);

  const { pathname } = window.location;

  if (pathname === '/authorized') {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    window.history.pushState({}, '', `${window.origin}/#/home/device?feature-flag=device&code=${code}`);
  }

  const skipStep = (nextStep: string) => {
    history.push(nextStep);
  };

  const leftNavDisplay = <LeftNav />;

  return (
    <UserContext.Provider value={contextUser}>
      <SelectedUserContext.Provider value={memoSelectedUser}>
        <ResumeContext.Provider value={{ appResumeCount }}>
          <Flex flexDir="row" h="100%" width="100%">
            {hasOnboarded && !isMobileDevice && leftNavDisplay}
            <Flex
              flex="1"
              minWidth="0"
              direction="column"
              h="100%"
              bg={props.isPir ? '' : '#F1F1F0'}
              pr={['0px', '0px', '0px', '0px', '17px']}
            >
              <Menu open={menuDisclosure.isOpen} closeMenu={menuDisclosure.onClose} />
              <ModalAlert
                isOpen={modalAlertDisclosure.isOpen}
                onClose={modalAlertDisclosure.onClose}
                source="notification"
              />
              {hasOnboarded && <Header />}
              <Flex flex="1" width="100%" overflowX="auto" direction="column">
                <Switch>
                  <Route path="/logout" component={LogOut} />

                  {!hasOnboarded && (
                    <Switch>
                      <Route exact={true} path="/">
                        <Onboarding />
                      </Route>
                      <Route exact={true} path="/informed-consent">
                        <InformedConsent />
                      </Route>
                      <Route exact={true} path="/enter-name">
                        <EnterName />
                      </Route>
                      {/* Onboarding process for non-admin / headOfOrg users */}
                      <Route exact={true} path="/fcm-token">
                        <GetFcmToken isPir={isPir} pirOnboarding={true} />
                      </Route>
                      <Route exact={true} path="/notifications">
                        <ManageUserInfoModal
                          isOpen={true}
                          onClose={() => {
                            // do nothing
                          }}
                          pir={props.selectedLinkedUserPir}
                          isPir={isPir}
                        />
                      </Route>
                      {isMobileDeviceAndCordova && (
                        <Route exact={true} path="/device">
                          <DeviceOnboarding />
                        </Route>
                      )}
                      <Route exact={true} path="/contact">
                        <ManageContactModal
                          isOpen={true}
                          onOpen={() => {
                            // do nothing
                          }}
                          onClose={() => {
                            // do nothing
                          }}
                          pir={props.selectedLinkedUserPir}
                        />
                      </Route>
                      <Route exact={true} path="/medication">
                        <MedicationModal
                          isOpen={true}
                          onOpen={() => {
                            // do nothing
                          }}
                          onClose={() => skipStep('/survey')}
                        />
                      </Route>
                      <Route exact={true} path="/survey">
                        <SurveyModal
                          isOpen={true}
                          onOpen={() => {
                            // do nothing
                          }}
                          onClose={() => skipStep('/get-started')}
                        />
                      </Route>
                      <Route exact={true} path="/get-started">
                        <GetStarted />
                      </Route>
                    </Switch>
                  )}

                  {hasOnboarded && !loggingOut && (
                    <>
                      <AppointmentsContext.Provider value={memoAppointments}>
                        <Route exact={true} path="/">
                          {urole === UserRole.CP && props.selectedLinkedUserPir ? (
                            <Redirect to="/dashboard" />
                          ) : (
                            <Redirect to="/home" />
                          )}
                        </Route>
                        <Route path="/home/:modal?">
                          <Home />
                        </Route>
                        <Route path="/profile">
                          {urole == UserRole.CP && selectedUser ? <ProviderDashboard /> : <Redirect to="/" />}
                        </Route>
                        <Route path="/vitals">
                          {urole === UserRole.USER || selectedUser ? <Vital /> : <Redirect to="/" />}
                        </Route>
                        <Route path="/dashboard">{urole === UserRole.CP ? <Dashboard /> : <Redirect to="/" />}</Route>
                        <Route path="/daily" component={Daily} />
                        {isCordova && <Route path="/device" component={DevicePage} />}
                        <Route path="/messages" component={Messages} />
                        <Route path="/physiology" component={Physiology} />
                        <Route path="/places" component={Places} />
                        <Route path="/photos" component={Photos} />
                        <Route path="/alerts" component={Alerts} />
                        <Route exact={true} path="/authorize-garmin">
                          <Redirect to={`/home/device${window.location.search}`} />
                        </Route>
                      </AppointmentsContext.Provider>
                    </>
                  )}
                </Switch>
              </Flex>

              {/* update fcm token for push notifications. for pirs, if they haven't been asked directly (for instance, they did onboarding on web), we want to initiate this even if doesWantPush is undefined; for CPs, we only want to initiate it here if they've said yes */}
              {isCordova &&
                hasOnboarded &&
                ((isPir && props.user?.doesWantPush !== false) ||
                  (urole === UserRole.CP && props.user?.doesWantPush === true)) && <GetFcmToken isPir={isPir} />}

              {/*   if they have onboarded but don't have any notification modes enabled, force them to enable one before continuing. */}
              {hasOnboarded &&
                !props.user?.doesWantPush &&
                !props.user?.doesWantEmail &&
                !props.user?.doesWantSms &&
                !props.user?.deletionStatus && (
                  <ManageUserInfoModal
                    isPir={isPir}
                    isOpen={true}
                    onClose={() => {
                      // do nothing
                    }}
                    pir={props.selectedLinkedUserPir}
                    noNotificationModeEnabled={true}
                  />
                )}

              {hasOnboarded && <FooterNav />}
            </Flex>
          </Flex>
        </ResumeContext.Provider>
      </SelectedUserContext.Provider>
    </UserContext.Provider>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;
  const { otherLinkedUsers } = state.linkedUsers;

  return {
    user: state.user.user,
    // garmin: state.garmin,
    otherLinkedUsersLength: state.linkedUsers.otherLinkedUsers === null ? 0 : state.linkedUsers.otherLinkedUsers.length,
    onboarding: state.onboarding.loading,
    hasOnboarded: state.onboarding.hasOnboarded,
    selectedLinkedUserPir: selectedLinkedUser?.pir || null,
    isPir: selectedLinkedUser !== null && isPirLinkedUser(selectedLinkedUser),
    geolocationEnabled: state.garmin.geolocationStatus,
    loggingOut: state.auth.loggingOut,
    otherLinkedUsers,
    supportNetwork: state.linkedUsers.supportNetwork,
  };
};

const mapDispatchToProps = {
  onboarded,
  updateUserTimezone,
  getToDos,
  updatePartialUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Index);
