import {
  setGarmin,
  updateBattery,
  garminSyncStart,
  garminSyncFail,
  garminUploadStart,
  garminUploadFail,
  garminUploadSuccess,
  garminDBStatus,
  garminUploadProgress,
  clearGarmin,
  garminSyncStop,
} from '../redux/actions/garmin';
import { store } from '../redux/store';
import Firestore from '../modules/firestore/Firestore';
import IGarmin from '../redux/interfaces/IGarmin';
import * as Sentry from '@sentry/react';
import sizeof from 'firestore-size';
import { serializeError } from 'serialize-error';
import { UpdatedUserAction } from 'src/modules/user/actions';
import IUser, { DevicePairedStatus } from 'src/modules/user/interfaces/IUser';
import { documentRef } from 'src/modules/firebase/firestore';
import firebase from 'firebase/compat/app';
import IGarminSdkStatus, { formatSdkStatusFromFirestore } from 'src/modules/initialize/IGarminSdkStatus';

export enum BehaiviorGarminDataType {
  BDTHeartRate = 1,
  BDTBBI = 2,
  BDTSteps = 3,
  BDTStress = 4,
  BDTZeroCrossing = 5,
  BDTPulseOx = 6,
  BDTRespiration = 7,
  BDTAccelerometer = 8,
}

export enum BluetoothStatus {
  UNAUTHORIZED = 'unauthorized',
  BLUETOOTH_ON = 'bluetooth_on',
  BLUETOOTH_NOT_ON = 'bluetooth_not_on',
}

export enum BluetoothAuthState {
  GRANTED = 'granted',
  DENIED_ALWAYS = 'denied_always',
  NOT_REQUESTED = 'not_requested',
  DENIED = 'denied',
  RESTRICTED = 'restricted',
}

export enum BluetoothState {
  BLUETOOTH_ON = 'bluetooth_on',
  BLUETOOTH_NOT_ON = 'bluetooth_not_on',
  BLUETOOTH_DENIED = 'bluetooth_denied',
  BLUETOOTH_NOT_REQUESTED = 'bluetooth_not_requested',
}

export enum NeedsToPairStatus {
  TRYING_TO_PAIR = 'TRYING_TO_PAIR',
  USER_SHOULD_PAIR = 'USER_SHOULD_PAIR',
  DOES_NOT_NEED_TO_PAIR = 'DOES_NOT_NEED_TO_PAIR',
  SHOULD_TRY_TO_PAIR = 'SHOULD_TRY_TO_PAIR',
}

export const isThisAndroid = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /android/.test(userAgent);
};

export const dbCollectionName: { [key: number]: string } = {
  1: 'garminSdkHeartRate',
  2: 'garminSdkBBI',
  3: 'garminSdkSteps',
  4: 'garminSdkStress',
  5: 'garminSdkZeroCrossing',
  6: 'garminSdkPulseOx',
  7: 'garminSdkRespiration',
  8: 'garminSdkAccelerometer',
};

const timeout = (ms: number) =>
  new Promise((_, reject) => setTimeout(() => reject(new Error('Operation timed out')), ms));

export const wearableInitializeSDK = async (): Promise<boolean> => {
  console.log('wearableInitializeSDK');
  try {
    // add timeout in case it gets stuck initializing
    const alreadyInitialized = await Promise.race([cordova.plugins.behaiviorGarmin.initializeSDK(), timeout(10000)]);
    console.log('Garmin SDK initialized successfully and alreadyInitialied is', alreadyInitialized);
    const isAlreadyInitialized = alreadyInitialized === true || alreadyInitialized === 'SDK already initialized';
    console.log('isAlreadyInitialized', isAlreadyInitialized);
    return isAlreadyInitialized;
  } catch (err) {
    Sentry.captureException(new Error(`Error initializing SDK: ${err}`));
    console.error(`Error initializing SDK: ${err}`);
    return false;
  }
};

export const garminPluginInitializeSentry = async (): Promise<void> => {
  console.log('garminPluginInitializeSentry');
  try {
    await cordova.plugins.behaiviorGarmin.initializeSentry();
    console.log('Sentry initialized successfully.');
  } catch (err) {
    Sentry.captureException(new Error(`Error initializing Sentry: ${err}`));
    console.error(`Error initializing Sentry: ${err}`);
  }
};

export const wearablePair = async (
  pirId: string,
  updatePartialUser: (id: string, userUpdates: Partial<IUser>) => Promise<UpdatedUserAction>,
): Promise<void> => {
  console.log('wearablePair');
  try {
    const garminState = store.getState().garmin;
    // make sure no devices are paired
    console.log('unpairDevice');
    await cordova.plugins.behaiviorGarmin.unpairDevice();
    console.log('unpairDevice returned');
    // establish new connection
    console.log('pairDevice');
    await cordova.plugins.behaiviorGarmin.pairDevice();
    console.log('pairDevice returned');
    Sentry.captureMessage('wearable was paired and will update devicePairedStatus to paired');
    await updatePartialUser(pirId, { devicePairedStatus: DevicePairedStatus.PAIRED });
    addSdkStatusRecord(garminState);
  } catch (err) {
    console.error(`Error pairing Garmin device: ${err}`);
    Sentry.captureException(new Error(`WearablePair failed: ${err}`));
    throw err;
  }
};

export const reInitializeSdk = async (): Promise<void> => {
  console.log('reInitializeSdk');
  try {
    await wearableInitializeSDK();
  } catch (err) {
    throw new Error(
      'Error connecting to Garmin. Try closing and reopening your Recovery app, and make sure your device is synced in the Garmin Connect app',
    );
  }
};

// for ios and Android
export const requestBluetoothAuth = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    const permissions = isThisAndroid() ? ['BLUETOOTH_CONNECT', 'BLUETOOTH_SCAN'] : undefined;
    diagnostic.requestBluetoothAuthorization(
      function () {
        resolve();
      },
      function (error: any) {
        console.error(`Error requesting bluetooth authorization for phone: ${error}`);
        reject(error);
      },
      permissions,
    );
  });
};

// for ios and Android
export const checkBluetoothAuthStatus = (): Promise<BluetoothAuthState> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    diagnostic.getBluetoothAuthorizationStatus(
      function (bluetoothAuthorized: BluetoothAuthState) {
        resolve(bluetoothAuthorized);
      },
      function (error: any) {
        console.error(`Error getting bluetooth auth status: ${error}`);
        reject(null);
      },
    );
  });
};

// for Android only
export const isBluetoothEnabled = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    diagnostic.isBluetoothEnabled(
      function (bluetoothOn: boolean) {
        resolve(bluetoothOn);
      },
      function (error: any) {
        console.error(`Error getting bluetooth auth status: ${error}`);
        reject(null);
      },
    );
  });
};

// for Android only
export const setBluetoothStatus = (bluetooth: boolean): Promise<void> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    diagnostic.setBluetoothState(
      function () {
        resolve();
      },
      function (error: any) {
        console.error(`Error setting bluetooth status for Android: ${error}`);
        reject(error);
      },
      bluetooth,
    );
  });
};

export const goToAppSettings = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const diagnostic = window.cordova.plugins.diagnostic;
    diagnostic.switchToSettings(
      function () {
        resolve();
      },
      function (error: any) {
        console.error(`Error switching to app settings: ${error}`);
        reject(error);
      },
    );
  });
};

export const wearableForget = async (
  pirId: string,
  updatePartialUser: (id: string, userUpdates: Partial<IUser>) => Promise<UpdatedUserAction>,
  userInitiated?: boolean,
): Promise<void> => {
  console.log('wearableForget');
  try {
    await cordova.plugins.behaiviorGarmin.unpairDevice();
    const pairedStatus = userInitiated ? DevicePairedStatus.USER_UNPAIRED : DevicePairedStatus.BECAME_UNPAIRED;
    Sentry.captureMessage(`wearable was forgotten and will update devicePairedStatus to ${pairedStatus}`);
    console.log(`wearable was forgotten and will update devicePairedStatus to ${pairedStatus}`);
    await updatePartialUser(pirId, { devicePairedStatus: pairedStatus });
    store.dispatch(clearGarmin());
  } catch (err) {
    Sentry.captureException(new Error(`Error forgetting wearable: ${err}`));
    console.error(`Error forgetting wearable: ${err}`);
  }
};

export const wearableSync = async (): Promise<void> => {
  console.log('wearableSync');
  // check bluetooth before attempting to sync:
  const garminState = store.getState().garmin;
  if (
    garminState.bluetoothStatus === BluetoothStatus.BLUETOOTH_ON &&
    !garminState.isSyncing &&
    !garminState.isUploading
  ) {
    Sentry.captureMessage('wearable sync is being called from the front end');
    store.dispatch(garminSyncStart());
    try {
      await cordova.plugins.behaiviorGarmin.requestSync();
    } catch (err) {
      const error = `${serializeError(err)}`;
      console.log({ error: error }, 'Wearable sync error');
      Sentry.captureException(new Error(`WearableSync failed: ${error}`));
      if (error === 'Will not attempt to sync because phone is locked') {
        store.dispatch(garminSyncStop());
      } else {
        store.dispatch(garminSyncFail());
      }

      throw err;
    }
  }
};

export const wearableBackgroundSync = async (): Promise<void> => {
  console.log('wearableBackgroundSync');
  Sentry.captureMessage('trying to run wearable background sync');
  // check bluetooth and that device is paired before attempting to sync:
  const garminState = store.getState().garmin;
  if (garminState.name && garminState.bluetoothStatus === BluetoothStatus.BLUETOOTH_ON) {
    store.dispatch(garminSyncStart());
    try {
      console.log('cordova.plugins.behaiviorGarmin.requestBackgroundSync()');
      Sentry.captureMessage('device is paired and bluetooth is on and about to call background sync in plugin');
      await cordova.plugins.behaiviorGarmin.requestBackgroundSync();
      Sentry.captureMessage('background sync completed');
    } catch (err) {
      console.log({ error: serializeError(err) }, 'Wearable background sync error');
      Sentry.captureException(new Error(`WearableBackgroundSync failed: ${serializeError(err)}`));
      store.dispatch(garminSyncStop());

      throw err;
    }
  }
};

/*
 * This method persist the wearable data to firestore. The useNativePersistence argument is used to control whether the
 * data is persisted to firestore using a native implementation provided by behaivior cordova plugin or firestore lib
 * directly. The plugin is used when running the sync in background mode because the web view used by the background
 * fetch is unable to communicate to firestore when the app is in background for a while.
 * */

const MAX_FIREBASE_DOCUMENT_SIZE = 850 * 1024;

export const uploadWearableData = async (useNativePersistence = false, isBackgroundSync = false): Promise<void> => {
  console.log('uploadWearableData');
  const pir = store.getState().linkedUsers.selectedLinkedUser?.pir;
  const garminState = store.getState().garmin;
  let timeout = false;

  if (!pir) {
    console.log('no pir');
    return;
  }

  if (!garminState) {
    console.log('no garminState');
    return;
  }

  if (garminState.isUploading) {
    console.log('upload already in progress');
    return;
  }

  const endTime = garminState.lastSyncCompleted ? new Date(garminState.lastSyncCompleted) : new Date();
  // let errorStartTime: Date = new Date();
  let startTime: Date = new Date(endTime);
  if (garminState.lastUploadCompleted) {
    startTime = new Date(garminState.lastUploadCompleted);
  } else {
    try {
      const previousStatusRecord = await getPreviousSdkStatusRecord(pir);
      if (previousStatusRecord) {
        startTime = previousStatusRecord.endTime;
      } else {
        startTime.setMinutes(startTime.getMinutes() - 15);
      }
    } catch (err) {
      console.error(`Error getting previous SDK status record: ${err}`);
      Sentry.captureException(new Error(`Error getting previous SDK status record: ${err}`));
    }
  }

  const errors = [];
  try {
    store.dispatch(garminUploadStart());
    try {
      console.log(`upload started from ${startTime.toISOString()} to ${endTime.toISOString()}`);
      Sentry.captureMessage(`upload started for ${pir.id} from ${startTime.toISOString()} to ${endTime.toISOString()}`);
      const chunks = chunkTimeInterval(startTime, endTime, 3600 * 1000 * 24);
      console.log(`uploadWearableData: uploading in ${chunks.length} chunks`);
      for (let i = 0; i < chunks.length; i++) {
        // Read directly from the store to get a fresh value for the timeout
        if (store.getState().garmin?.backgroundSyncTimeout) {
          timeout = true;
          console.warn('uploadWearableData: timeout, stopping work gracefully');
          break;
        }

        const { chunkStart, chunkEnd } = chunks[i];
        console.log(`uploadWearableData: chunk:${i}: getData ${chunkStart}, ${chunkEnd}`);
        const deviceData = await cordova.plugins.behaiviorGarmin.getData(chunkStart, chunkEnd);
        console.log(`uploadWearableData: chunk:${i}: Storing received data`);
        for (const dataType of garminState.dataset) {
          const data = deviceData[dataType];
          const collectionName = dbCollectionName[dataType];
          const size = sizeof(data);
          if (data && data.length > 0) {
            console.log(
              `uploadWearableData: chunk:${i}: Storing received data for collection:${collectionName}, record count:${data.length}, estimated size:${size}`,
            );
            Sentry.captureMessage(
              `uploadWearableData for ${pir.id}: chunk:${i}: Storing received data for collection:${collectionName}, record count:${data.length}, estimated size:${size}`,
            );

            // for some types of data, we're getting data every second, but for others every 30 seconds, so some types of data might exceed firebase document size limits depending on time between syncs
            if (size > MAX_FIREBASE_DOCUMENT_SIZE) {
              console.log('Batching into smaller chunks because data is larger than Firebase document size limit');
              const numChunksNeeded = Math.ceil(size / MAX_FIREBASE_DOCUMENT_SIZE);
              const batchSize = Math.floor(data.length / numChunksNeeded);
              for (let j = 0; j < data.length; j += batchSize) {
                const batch = data.slice(j, j + batchSize);
                try {
                  const batchSizeEstimate = sizeof(batch);
                  const batchStart = batch[0].timestamp ? new Date(batch[0].timestamp) : chunkStart;
                  const batchEnd = batch[batch.length - 1].timestamp
                    ? new Date(batch[batch.length - 1].timestamp)
                    : chunkEnd;
                  console.log(
                    `Storing batch ${
                      j / batchSize + 1
                    }: size estimate ${batchSizeEstimate} from ${batchStart} to ${batchEnd}`,
                  );

                  Sentry.captureMessage(
                    `Storing batch ${
                      j / batchSize + 1
                    }: size estimate ${batchSizeEstimate} from ${batchStart} to ${batchEnd} for ${pir.id}`,
                  );

                  if (useNativePersistence) {
                    await cordova.plugins.behaiviorGarmin.persistData(
                      collectionName,
                      pir.id,
                      batchStart,
                      batchEnd,
                      batch,
                    );
                  } else {
                    await Firestore.collection(collectionName).doc().set({
                      pir,
                      startTime: batchStart,
                      endTime: batchEnd,
                      data: batch,
                    });
                  }
                } catch (err) {
                  errors.push({ collection: collectionName, error: err, chunk: i, batch: j / batchSize + 1 });
                  Sentry.captureException(
                    new Error(`uploadWearableData failed, look at the breadcrumbs for ${pir.id} with {err}`),
                  );
                }
              }
            } else {
              try {
                if (useNativePersistence) {
                  await cordova.plugins.behaiviorGarmin.persistData(collectionName, pir.id, chunkStart, chunkEnd, data);
                } else {
                  await Firestore.collection(collectionName).doc().set({
                    pir,
                    startTime: chunkStart,
                    endTime: chunkEnd,
                    data,
                  });
                }
              } catch (e) {
                Sentry.captureException(
                  new Error(`uploadWearableData failed, look at the breadcrumbs for ${pir.id} with {e}`),
                );
                errors.push({ collection: collectionName, error: e, chunk: i });
              }
            }
          } else {
            console.log(`uploadWearableData: chunk:${i}: Nothing to store for collection:${collectionName}`);
            // for heartRate (dataType 1), which comes in every second, for keeping track of any errors or missing data, it is helpful to know whenever, during an upload, no data was found
            if (dataType === 1) {
              if (useNativePersistence) {
                Sentry.captureMessage(
                  `uploadWearableData: Nothing to store for heartRate from ${chunkStart} to ${chunkEnd}`,
                );
              } else {
                try {
                  console.log(`uploadWearableData: Nothing to store for heartRate from ${chunkStart} to ${chunkEnd}`);
                  await Firestore.collection('noGarminSdkHeartRateData').doc().set({
                    pir,
                    startTime: chunkStart,
                    endTime: chunkEnd,
                  });
                } catch (err) {
                  console.log(`Error updating firestore with noGarminSdkHeartRate: ${err}`);
                  Sentry.captureMessage(`Error updating firestore with noGarminSdkHeartRate: ${err}`);
                }
              }
            }
          }

          Sentry.captureMessage(`about to update progress for ${chunkEnd}`);
          console.log(`about to update progress for ${chunkEnd}`);
          store.dispatch(garminUploadProgress((i * 100) / chunks.length, chunkEnd.toISOString()));
        }
      }
      if (errors.length > 0) {
        errors.forEach(({ collection, error, chunk }) =>
          console.log(
            { error: serializeError(error), collection: collection },
            `uploadWearableData: chunk:${chunk}: failed with error for collection, ${error}`,
          ),
        );
        Sentry.captureException(new Error('uploadWearableData failed, look at the breadcrumbs'));
        store.dispatch(garminUploadFail());
      } else if (garminState.backgroundSyncTimeout == true) {
        console.log('uploadWearableData: timeout');
        Sentry.captureException(new Error('uploadWearableData failed: backgroundSyncTimeout'));
        store.dispatch(garminUploadFail());
      } else {
        Sentry.captureMessage('garmin upload success');
        console.log('uploadWearableData: success');
        store.dispatch(garminUploadSuccess());
      }
    } catch (err) {
      console.log({ error: serializeError(err) }, 'uploadWearableData: failed with error');
      Sentry.captureException(new Error(`uploadWearableData failed with errors: ${serializeError(err)}`));
      store.dispatch(garminUploadFail());
    }
  } finally {
    await updateSdkStatusRecord(timeout, useNativePersistence, isBackgroundSync);
  }
};

type Chunk = {
  chunkStart: Date;
  chunkEnd: Date;
};

const chunkTimeInterval = (startTime: Date, endTime: Date, chunkSize: number): Array<Chunk> => {
  const chunks: Array<Chunk> = [];
  let start = startTime.getTime();
  while (start < endTime.getTime()) {
    const end = Math.min(start + chunkSize, endTime.getTime());
    chunks.push({ chunkStart: new Date(start), chunkEnd: new Date(end) });
    start = end + 1;
  }
  return chunks;
};

export const setupWearableLogging = async (): Promise<void> => {
  console.log('setupWearableLogging');
  try {
    await cordova.plugins.behaiviorGarmin.setupLoggingState();
  } catch (err) {
    console.error('Error setting up wearable logging', err);
    Sentry.captureException(new Error(`Error setting up wearable logging: ${err}`));
    throw err;
  }
};

export const getWearableLogging = async (): Promise<void> => {
  console.log('getWearableLogging');
  try {
    await cordova.plugins.behaiviorGarmin.checkLoggingState();
  } catch (err) {
    console.error('Error getting wearable logging', err);
    Sentry.captureException(new Error(`Error getting wearable logging: ${err}`));
  }
};

export const getWearableBatteryStatus = async (): Promise<void> => {
  console.log('getWearableBatteryStatus');
  try {
    const batteryLevel = await cordova.plugins.behaiviorGarmin.getBatteryStatus();
    if (batteryLevel >= 0) {
      store.dispatch(updateBattery(batteryLevel));
    }
  } catch (err) {
    console.error(`Error getting wearable battery status: ${err}`);
    Sentry.captureException(new Error(`Error getting wearable battery status: ${err}`));

    throw err;
  }
};

export const isDownloadingLoggedData = async (): Promise<boolean> => {
  console.log('isDownloadingLoggedData');
  return (await cordova.plugins.behaiviorGarmin.isDownloadingLoggedData()) == 1 ? true : false;
};

export const getWearableInfo = async (): Promise<{
  friendlyName: string;
  connected: boolean;
}> => {
  console.log('getWearableInfo');
  try {
    const deviceInfo = await cordova.plugins.behaiviorGarmin.getPairedDevice();
    if (deviceInfo) {
      store.dispatch(setGarmin(deviceInfo.friendlyName));
    } else {
      throw new Error('No device found');
    }
    return deviceInfo;
  } catch (err) {
    console.error('Error getting wearable info', err);
    Sentry.captureException(new Error(`Error getting wearable info: ${err}`));
    throw err;
  }
};

// Firebase interactions
const addSdkStatusRecord = async (garmin: IGarmin): Promise<void> => {
  console.log('addSdkStatusRecord');
  const pirLU = store.getState().linkedUsers.pir;
  const pir = pirLU?.pir;
  const pirPath = pirLU === null ? undefined : pirLU.pir.path;
  if (!garmin.id || !garmin.pir) {
    console.log('missing garmin id or pir');
    const previousRecord = await getPreviousSdkStatusRecord(pir);
    let deviceSdkRef, lastUploadCompleted;
    if (previousRecord) {
      console.log('previousRecord', previousRecord.endTime);
      deviceSdkRef = Firestore.collection('garminSdkStatus').doc(previousRecord.id);
      lastUploadCompleted = previousRecord.endTime.toISOString();
    } else {
      deviceSdkRef = Firestore.collection('garminSdkStatus').doc();
      const lastUpload = new Date();
      lastUpload.setMinutes(lastUpload.getMinutes() - 15);
      lastUploadCompleted = lastUpload.toISOString();
    }
    await store.dispatch(garminDBStatus(deviceSdkRef.path, pirPath, lastUploadCompleted));
  }
};

const getPreviousSdkStatusRecord = async (
  pir: firebase.firestore.DocumentReference | undefined,
): Promise<IGarminSdkStatus | undefined> => {
  console.log('getprevioussdkstatusrecord');
  if (pir) {
    try {
      const previousGarminDeviceSnapshot = await Firestore.database()
        .collection('garminSdkStatus')
        .where('pir', '==', pir)
        .get();

      if (previousGarminDeviceSnapshot.empty) {
        return undefined;
      }
      const previousGarminDevice: IGarminSdkStatus = formatSdkStatusFromFirestore(previousGarminDeviceSnapshot.docs[0]);

      return previousGarminDevice;
    } catch (err) {
      console.error(`Error getting previous SDK status record: ${err}`);
      Sentry.captureException(new Error(`Error getting previous SDK status record: ${err}`));
    }
  } else {
    return undefined;
  }
};

export const checkIfUserWasPreviouslyPaired = async (userId: string) => {
  try {
    const pirDocRef = documentRef('users', userId);
    const previousGarminDevice = await Firestore.database()
      .collection('garminSdkStatus')
      .where('pir', '==', pirDocRef)
      .get();
    if (!previousGarminDevice.empty) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error(`Error checking if user was previously paired: ${err}`);
  }
};

const updateSdkStatusRecord = async (
  timeout: boolean,
  useNativePersistence: boolean,
  isBackgroundSync: boolean,
): Promise<void> => {
  try {
    const garmin = store.getState().garmin;
    console.log('updateSdkStatusRecord');

    if (garmin.id && garmin.pir) {
      const now = new Date();
      const endTime = garmin.lastUploadCompleted ? new Date(garmin.lastUploadCompleted) : now;
      if (useNativePersistence) {
        try {
          await cordova.plugins.behaiviorGarmin.updateSdkStatus(
            garmin.id,
            garmin.pir,
            endTime,
            garmin.syncErrors,
            timeout,
          );
        } catch (err) {
          console.error('updateSdkStatusRecord: error updating record in plugin', err);
          Sentry.captureException(new Error(`updateSdkStatus reccord error: ${err}`));
        }
      } else {
        try {
          const pirDocRef = Firestore.database().doc(garmin.pir);
          const dataDocRef = Firestore.database().doc(garmin.id);
          const executionLogDocRef = dataDocRef.collection('syncExecutionLogs').doc();

          const batch = Firestore.database().batch();
          batch.set(dataDocRef, {
            pir: pirDocRef,
            endTime,
            syncErrors: garmin.syncErrors,
            // lastUploadCompleted: garmin.lastUploadCompleted,
            // deviceId: garmin.deviceId,
          });
          batch.set(executionLogDocRef, {
            pir: pirDocRef,
            endTime,
            executionTime: now,
            task: isBackgroundSync ? 'backgroundSync' : 'sync',
            platform: window.device.platform,
            syncErrors: garmin.syncErrors,
            timeout,
          });
          await batch.commit();
        } catch (err) {
          console.error('updateSdkStatusRecord: Firestore batch error', err);
          Sentry.captureException(new Error(`updateSdkStatusRecord firestore batch error: ${err}`));
        }
      }
    } else {
      console.warn('updateSdkStatusRecord: Missing garmin ID or PIR');
    }
  } catch (err) {
    console.error('updateSdkStatusRecord: Unexpected error', err);
    Sentry.captureException(`updateSdkStatusRecord unexpected error: ${err}`);
  }
};
