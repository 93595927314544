import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from '../auth/actions';
import { wearableForget } from '../../lib/wearable';
import { RootState } from '../../redux/store';
import * as Sentry from '@sentry/react';
import { updatePartialUser } from '../user/actions';

const LogOut = (props: PropsFromRedux): JSX.Element => {
  const logOutSteps = async () => {
    if (window.cordova) {
      Sentry.captureMessage('Logging out and forgetting wearable');
      try {
        await wearableForget(props.userId, props.updatePartialUser);
      } catch (err) {
        console.error(`Error forgetting wearable: ${err}`);
      }
    }
    try {
      await props.logout();
    } catch (err) {
      console.error(`Error logging out: ${err}`);
    }
  };

  useEffect(() => {
    logOutSteps();
  }, []);

  return <Redirect to="/" />;
};

const mapStateToProps = (state: RootState) => {
  const user = state.user?.user;
  if (!user) {
    throw new Error('No user logged in during logout');
  }
  const userId = user.id;

  return {
    userId,
  };
};

const mapDispatchToProps = {
  logout,
  updatePartialUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LogOut);
