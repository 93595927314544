import { BluetoothStatus, NeedsToPairStatus } from 'src/lib/wearable';
import { GarminActions, GarminActionTypes } from '../actions/garmin';
import IGarmin from '../interfaces/IGarmin';

const defaultGarmin: IGarmin = {
  id: undefined,
  needsToPair: undefined,
  pir: undefined,
  endTime: undefined,
  name: undefined,
  deviceId: undefined,
  deviceIsConnected: false,
  isSyncing: false,
  syncProgress: undefined,
  lastSyncStarted: undefined,
  lastSyncCompleted: undefined,
  isUploading: false,
  uploadProgress: undefined,
  lastUploadStarted: undefined,
  lastUploadCompleted: undefined,
  battery: -1,
  dataset: [],
  syncErrors: 0,
  syncErrorDetails: undefined,
  geolocationStatus: false,
  hourOffset: false,
  backgroundSyncTimeout: false,
  bluetoothStatus: undefined,
  uploadStartTime: undefined,
  syncStartTime: undefined,
};

export const garminReducer = (state: IGarmin = defaultGarmin, action: GarminActions): IGarmin => {
  switch (action.type) {
    case GarminActionTypes.SET_GARMIN:
      console.log('set_garmin', state.isSyncing, state.isUploading);
      return {
        ...state,
        name: action.name,
        dataset: action.dataset,
        hourOffset: action.hourOffset,
        syncErrors: 0,
        syncErrorDetails: undefined,
        deviceIsConnected: true,
        needsToPair: NeedsToPairStatus.DOES_NOT_NEED_TO_PAIR,
      };
    case GarminActionTypes.UPDATE_BATTERY:
      console.log('update_battery', state.isSyncing, state.isUploading);
      return {
        ...state,
        battery: action.battery,
      };
    case GarminActionTypes.CLEAR_GARMIN:
      console.log('clear_garmin', state.isSyncing, state.isUploading);
      return {
        ...state,
        name: undefined,
        isSyncing: false,
        isUploading: false,
        uploadStartTime: undefined,
        syncStartTime: undefined,
        battery: 0,
        dataset: [],
        syncErrors: 0,
        syncErrorDetails: undefined,
        deviceIsConnected: false,
      };
    case GarminActionTypes.UPDATE_DEVICE_IS_CONNECTED:
      console.log('is_connected', state.isSyncing, state.isUploading);
      return {
        ...state,
        deviceIsConnected: action.deviceIsConnected,
        needsToPair: NeedsToPairStatus.DOES_NOT_NEED_TO_PAIR,
      };
    case GarminActionTypes.UPDATE_NEEDS_TO_PAIR_ACTION:
      console.log('update_needs_to_pair', state.isSyncing, state.isUploading);
      return {
        ...state,
        needsToPair: action.needsToPair,
      };
    case GarminActionTypes.GARMIN_BACKGROUND_SYNC_TIMEOUT:
      console.log('sync_timeout', state.isSyncing, state.isUploading);
      return {
        ...state,
        isSyncing: false,
        isUploading: false,
        uploadStartTime: undefined,
        syncStartTime: undefined,
        backgroundSyncTimeout: true,
      };
    case GarminActionTypes.GARMIN_SYNC_START:
      console.log('sync_start', state.isSyncing, state.isUploading);
      return {
        ...state,
        isSyncing: true,
        syncStartTime: Date.now(),
        syncProgress: 0,
        uploadProgress: 0,
        lastSyncStarted: new Date().toISOString(),
        backgroundSyncTimeout: false,
        bluetoothStatus: BluetoothStatus.BLUETOOTH_ON,
        syncErrorDetails: undefined,
        deviceIsConnected: true,
      };
    case GarminActionTypes.GARMIN_SYNC_PROGRESS:
      console.log('sync_progress', state.isSyncing, state.isUploading);
      return {
        ...state,
        isSyncing: true,
        syncStartTime: Date.now(),
        syncProgress: action.progress,
      };
    case GarminActionTypes.GARMIN_SYNC_FAIL:
      console.log('sync_fail', state.isSyncing, state.isUploading);
      return {
        ...state,
        isSyncing: false,
        syncStartTime: undefined,
        syncErrors: state.syncErrors + 1,
        backgroundSyncTimeout: false,
        syncErrorDetails: action.errorDetail,
      };
    case GarminActionTypes.GARMIN_SYNC_STOP:
      console.log('sync_stop', state.isSyncing, state.isUploading);
      return {
        ...state,
        isSyncing: false,
        syncStartTime: undefined,
        backgroundSyncTimeout: false,
      };
    case GarminActionTypes.GARMIN_SYNC_SUCCESS:
      console.log('sync_success', state.isSyncing, state.isUploading);
      return {
        ...state,
        isSyncing: false,
        syncStartTime: undefined,
        syncErrors: 0,
        lastSyncCompleted: state.lastSyncStarted,
        syncErrorDetails: undefined,
      };
    case GarminActionTypes.UPDATE_BLUETOOTH_STATUS:
      console.log('update_bluetooth', state.isSyncing, state.isUploading);
      return {
        ...state,
        bluetoothStatus: action.status,
      };
    case GarminActionTypes.GARMIN_UPLOAD_START:
      console.log('upload_start', state.isSyncing, state.isUploading);
      return {
        ...state,
        isUploading: true,
        uploadStartTime: Date.now(),
        syncProgress: 100,
        uploadProgress: 0,
        lastUploadStarted: state.lastSyncCompleted,
      };
    case GarminActionTypes.GARMIN_UPLOAD_PROGRESS:
      console.log('upload_progress', state.isSyncing, state.isUploading);
      return {
        ...state,
        isUploading: true,
        uploadStartTime: Date.now(),
        uploadProgress: action.progress,
        lastUploadCompleted: action.lastChunkCompleted,
      };
    case GarminActionTypes.GARMIN_UPLOAD_FAIL:
      console.log('upload_fail', state.isSyncing, state.isUploading);
      return {
        ...state,
        isUploading: false,
        uploadStartTime: undefined,
        syncErrors: state.syncErrors + 1,
        backgroundSyncTimeout: false,
        syncErrorDetails: action.errorDetail,
      };
    case GarminActionTypes.GARMIN_UPLOAD_SUCCESS:
      console.log('upload_success', state.isSyncing, state.isUploading);
      return {
        ...state,
        isUploading: false,
        uploadStartTime: undefined,
        syncErrors: 0,
        backgroundSyncTimeout: false,
        syncErrorDetails: undefined,
      };
    case GarminActionTypes.GARMIN_DB_STATUS:
      console.log('db_status', state.isSyncing, state.isUploading);
      return {
        ...state,
        id: action.id,
        pir: action.pir,
        lastUploadCompleted: action.lastUploadCompleted,
      };
    case GarminActionTypes.GEOLOCATION_STATUS_ON:
      return {
        ...state,
        geolocationStatus: true,
      };
    case GarminActionTypes.GEOLOCATION_STATUS_OFF:
      return {
        ...state,
        geolocationStatus: false,
      };
    case GarminActionTypes.LOGOUT:
      return {
        ...defaultGarmin,
        bluetoothStatus: state.bluetoothStatus,
      };

    default:
      return state;
  }
};
